import { Button, Container, FormControl, Grid, Input, InputAdornment, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../store';
import { PredictState } from '../store/predict';

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const PredictForm = observer(() => {
  const classes = useStyles();
  const { predictStore } = useContext(StoreContext);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (predictStore.state === PredictState.READY) {
      predictStore.appendPredictIfNeeded();
    } else if (predictStore.state === PredictState.PREDICTED) {
      predictStore.reset();
    }
  };

  const renderSubmitButton = () => {
    switch (predictStore.state) {
      case PredictState.PREDICTING:
        return (
          <Button
            type="submit"
            onTouchStart={onSubmit}
            onClick={onSubmit}
            className={classes.submit}
            variant="contained"
            color="secondary"
            fullWidth
            disabled
          >
            正在观测
          </Button>
        );
      case PredictState.PREDICTED:
        return (
          <Button
            type="submit"
            className={classes.submit}
            onTouchStart={onSubmit}
            onClick={onSubmit}
            variant="contained"
            color="secondary"
            fullWidth
          >
            切换世界线
          </Button>
        );
      case PredictState.READY:
      default:
        return (
          <Button
            type="submit"
            onTouchStart={onSubmit}
            onClick={onSubmit}
            className={classes.submit}
            variant="contained"
            color="primary"
            fullWidth
            disabled={!predictStore.isValidToken}
          >
            未来观测
          </Button>
        );
    }
  };

  return (
    <Container maxWidth="sm">
      <form autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <FormControl className={classes.formControl} fullWidth required>
              <InputLabel>世界线变动率</InputLabel>
              <Input
                name="token"
                type="tel"
                ref={(input) => predictStore.setFocusInput(input as HTMLInputElement)}
                inputRef={(input) => predictStore.setBlurInput(input as HTMLInputElement)}
                inputProps={{ min: 0, step: 1, maxLength: 4 }}
                startAdornment={<InputAdornment position="start">0.</InputAdornment>}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                autoFocus
                value={predictStore.token}
                disabled={predictStore.state !== PredictState.READY}
                onChange={(e) => predictStore.setToken(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl className={classes.formControl} fullWidth required>
              <InputLabel>面数</InputLabel>
              <Select
                name="face"
                value={predictStore.selectedFace}
                disabled={predictStore.state === PredictState.PREDICTING}
                onChange={async (e) => predictStore.setSelectedFace(parseInt(e.target.value as string, 10))}
              >
                <MenuItem value={4}>D4</MenuItem>
                <MenuItem value={6}>D6</MenuItem>
                <MenuItem value={8}>D8</MenuItem>
                <MenuItem value={10}>D10</MenuItem>
                <MenuItem value={12}>D12</MenuItem>
                <MenuItem value={20}>D20</MenuItem>
                <MenuItem value={100}>D100</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {renderSubmitButton()}
      </form>
    </Container>
  );
});

export default PredictForm;

import React, { useContext } from 'react';
import { AppBar, Toolbar, IconButton, Typography, makeStyles } from '@material-ui/core';
import CasinoIcon from '@material-ui/icons/Casino';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../store';

const useStyles = makeStyles(() => ({
  appBar: {
    paddingTop: 'env(safe-area-inset-top)',
    paddingLeft: 'env(safe-area-inset-left)',
    paddingRight: 'env(safe-area-inset-right)',
  },
}));

const NavBar = observer(() => {
  const classes = useStyles();
  const { themeStore } = useContext(StoreContext);

  const toggleTheme = () => {
    themeStore.setDark(!themeStore.isDark);
  };

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="菜单" onClick={toggleTheme}>
          <CasinoIcon />
        </IconButton>
        <Typography variant="h6">スカイクラッドの観测者</Typography>
      </Toolbar>
    </AppBar>
  );
});

export default NavBar;

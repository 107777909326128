import { makeAutoObservable } from 'mobx';

export class ThemeStore {
  isDark: boolean = window.matchMedia('(prefers-color-scheme: dark)').matches;

  constructor() {
    makeAutoObservable(this);
  }

  setDark(dark: boolean) {
    this.isDark = dark;
  }
}

export const themeStore = new ThemeStore();

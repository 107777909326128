import React, { createContext, FunctionComponent } from 'react';
import { predictStore } from './predict';
import { globalNotificationStore } from './globalNotification';
import { themeStore } from './theme';

export const stores = {
  predictStore,
  globalNotificationStore,
  themeStore,
};

export const StoreContext = createContext(stores);

export interface ProviderProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode
}

export const StoreProvider: FunctionComponent = ({ children }: ProviderProps) => (
  <StoreContext.Provider value={stores}>
    {children}
  </StoreContext.Provider>
);

import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, Collapse, Box, Typography, LinearProgress, makeStyles, IconButton, Container, TableBody, Dialog, DialogContent, DialogContentText, Button, DialogActions, DialogTitle } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import { StoreContext } from '../store';
import { PredictState } from '../store/predict';

const useStyles = makeStyles((theme) => ({
  bar: {
    height: 18,
  },
  label: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Consolas, SF Mono, Menlo, New Courier, monospace',
    textStroke: '0.5px black',
  },
  markedRow: {
    textDecorationLine: 'line-through',
    color: 'grey',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(6),
  },
}));

const PredictList = observer(() => {
  const classes = useStyles();
  const { predictStore } = useContext(StoreContext);
  const rows = predictStore.predictResults;
  const [jumpDialogOpen, setJumpDialogOpen] = useState(false);
  const [jumpIndex, setJumpIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (predictStore.state === PredictState.PREDICTED) {
        predictStore.updatePerdict();
      }
    }, 2000);
    return () => clearInterval(timer);
  });

  const calcSum = (index: number): number => predictStore.predictResults.slice(
    0, index + 1,
  ).map(
    (i) => i.result,
  ).reduce(
    (prev, now) => prev + now,
    0,
  );

  const jump = async (index: number) => {
    if (index === 0) {
      return;
    }
    await predictStore.jumpPredict(index);
    setJumpDialogOpen(false);
  };

  const openDialog = (index: number) => {
    setJumpIndex(index);
    setJumpDialogOpen(true);
  };

  return (
    <Container>
      {rows.length > 0
        ? (
          <>
            <Dialog open={jumpDialogOpen} onClose={() => setJumpDialogOpen(false)}>
              <DialogTitle>确认跳跃</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  跳跃到所选骰子？
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setJumpDialogOpen(false)}>取消</Button>
                <Button onClick={() => jump(jumpIndex)}>确认</Button>
              </DialogActions>
            </Dialog>
            <Collapse in={predictStore.state !== PredictState.READY && rows.length > 0}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="10%" />
                      <TableCell align="right" width="20%">#</TableCell>
                      <TableCell width="50%">结果</TableCell>
                      <TableCell align="right" width="20%">总和</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {predictStore.predictResults.map((result, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                      <TableRow key={index}>
                        <TableCell padding="checkbox">
                          <IconButton
                            disabled={index === 0}
                            onClick={() => openDialog(index)}
                          >
                            <SkipNextIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                        <TableCell scope="row" align="right">{index + 1}</TableCell>
                        <TableCell>
                          <Box alignItems="center" position="relative" display="flex">
                            <Box width="100%">
                              <LinearProgress
                                className={classes.bar}
                                variant="determinate"
                                value={(result.result / result.face) * 100}
                                color={result.result === result.face ? 'secondary' : 'primary'}
                              />
                            </Box>
                            <Box left={4} position="absolute">
                              <Typography className={classes.label} variant="subtitle1" component="div">
                                {result.result}
                              </Typography>
                            </Box>
                            <Box right={4} position="absolute">
                              <Typography className={classes.label} variant="subtitle1" component="div">
                                {result.face}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell scope="row" align="right">{calcSum(index)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </>
        )
        : null}
      {predictStore.state === PredictState.PREDICTED
        ? (
          <Box className={classes.loaderContainer}>
            <Typography variant="h6">到底儿啦！🎉</Typography>
          </Box>
        )
        : null}
    </Container>
  );
});

export default PredictList;

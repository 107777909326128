import { makeStyles, Theme, createStyles, Fab, Zoom } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { StoreContext } from '../store';

const useStyles = makeStyles((theme: Theme) => createStyles({
  refresher: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    paddingBottom: 'env(safe-area-inset-bottom)',
    paddingRight: 'env(safe-area-inset-right)',
    zIndex: theme.zIndex.snackbar + 1,
  },
}));

const RePredictButton = observer(() => {
  const classes = useStyles();
  const { predictStore } = useContext(StoreContext);

  const onClick = () => {
    predictStore.rePredict();
  };

  return (
    <Zoom in={predictStore.predictResults.length > 0}>
      <div className={classes.refresher}>
        <Fab color="secondary" onClick={onClick} onTouchStart={onClick}>
          <Refresh />
        </Fab>
      </div>

    </Zoom>
  );
});

export default RePredictButton;

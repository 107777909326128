import { SnackbarProvider } from 'notistack';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { StoreProvider } from './store';
import ThemeProvider from './theme';

export default () => (
  <React.StrictMode>
    <StoreProvider>
      <BrowserRouter>
        <ThemeProvider>
          <SnackbarProvider>
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </StoreProvider>
  </React.StrictMode>

);

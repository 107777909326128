import { makeAutoObservable, runInAction } from 'mobx';
import predictAPI from '../api';
import { globalNotificationStore } from './globalNotification';

interface PredictHTTPResponse {
  data: number[],
}

export interface PredictResult {
  face: number;
  result: number;
}

export enum PredictState {
  READY,
  PREDICTING,
  PREDICTED,
}

const isDigit = (s: string) => /^\d*$/.test(s);

export class PredictStore {
  state = PredictState.READY

  token = localStorage.getItem('token') || '';

  selectedFace: number = parseInt(localStorage.getItem('dice') || '20', 10);

  predictResults: PredictResult[] = [];

  markedRow: number | undefined = undefined;

  blurInputRef: HTMLInputElement | undefined = undefined;

  focusInputRef: HTMLInputElement | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isValidToken() {
    return isDigit(this.token) && this.token.length === 4;
  }

  rowMarked(index: number) {
    return this.markedRow !== undefined && index <= this.markedRow;
  }

  async appendPredict() {
    const prevState: PredictState = this.state;
    if (this.blurInputRef !== undefined) {
      this.blurInputRef.blur();
    }
    const start = this.predictResults.length;
    const end = start + 50;
    let results: PredictResult[];
    this.state = PredictState.PREDICTING;
    try {
      results = await this.getPredict(start, end);
    } catch (e) {
      if (e.response && e.response.status >= 400 && e.response.status < 500) {
        runInAction(() => {
          this.reset();
          globalNotificationStore.enqueueSnackbar('世界线观测失败', { variant: 'error' });
        });
        return;
      }
      runInAction(() => {
        this.state = prevState;
      });
      throw new Error(e);
    }
    runInAction(() => {
      results.forEach((result, index) => {
        this.predictResults[start + index] = result;
      });
      this.state = PredictState.PREDICTED;
    });
  }

  async updatePerdict() {
    const start = 0;
    const end = this.predictResults.length;
    let results: PredictResult[];
    try {
      results = await this.getPredict(start, end);
    } catch (e) {
      return;
    }
    runInAction(() => {
      results.forEach((result, index) => {
        this.predictResults[index] = result;
      });
    });
  }

  async getPredict(start: number, end: number): Promise<PredictResult[]> {
    const params = { face: this.selectedFace, start, end };
    const response = await predictAPI.get(
      `/predict/${this.token}`,
      { params },
    ).catch((e) => {
      throw e;
    });
    if (!response) {
      return [];
    }
    const data = response.data as PredictHTTPResponse;
    const results = data.data.map((i) => <PredictResult>{ face: this.selectedFace, result: i });
    return results;
  }

  async appendPredictIfNeeded() {
    if (this.state === PredictState.PREDICTING) {
      return;
    }
    await this.appendPredict();
  }

  async rePredict() {
    this.predictResults = [];
    this.markedRow = undefined;
    await this.appendPredictIfNeeded();
  }

  async jumpPredict(times: number) {
    await predictAPI.post(`/predict/${this.token}?face=${this.selectedFace}&times=${times}`);
    await this.rePredict();
  }

  setToken(token: string) {
    if (isDigit(token)) {
      this.token = token;
    }
    if (this.isValidToken) {
      localStorage.setItem('token', token);
      this.appendPredictIfNeeded();
    }
  }

  async setSelectedFace(face: number) {
    this.selectedFace = face;
    localStorage.setItem('dice', face.toString());
    if (this.state === PredictState.PREDICTED) {
      await this.rePredict();
    }
  }

  setMarkedRow(index: number) {
    if (index === this.markedRow) {
      this.markedRow = undefined;
    } else {
      this.markedRow = index;
    }
  }

  setBlurInput(e: HTMLInputElement) {
    this.blurInputRef = e;
  }

  setFocusInput(e: HTMLInputElement) {
    this.focusInputRef = e;
  }

  reset() {
    this.setToken('');
    this.predictResults = [];
    this.markedRow = undefined;
    this.state = PredictState.READY;
    if (this.blurInputRef !== undefined) {
      this.blurInputRef.focus();
    }
  }
}

export const predictStore = new PredictStore();

import React from 'react';
import Container from '@material-ui/core/Container';
import { Box, makeStyles } from '@material-ui/core';

import PredictForm from './component/PredictForm';
import NavBar from './component/NavBar';
import PredictList from './component/PredictList';
import Notifier from './component/Notifier';
import RePredictButton from './component/RePredictButton';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: {
    ...theme.mixins.toolbar,
    paddingTop: 'env(safe-area-inset-top)',
    paddingLeft: 'env(safe-area-inset-left)',
    paddingRight: 'env(safe-area-inset-right)',
  },
  container: {
    paddingTop: 'env(safe-area-inset-top)',
  },
}));

export default function App() {
  const classes = useStyles();
  return (
    <Box>
      <Notifier />
      <NavBar />
      <RePredictButton />
      <Container className={classes.container} maxWidth="sm">
        <div className={classes.appBarSpacer} />
        <PredictForm />
        <PredictList />
      </Container>
    </Box>
  );
}

import { createMuiTheme, ThemeProvider as MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo } from 'react';
import { StoreContext } from './store';

export interface ProviderProps {
  children?: React.ReactNode
}

const ThemeProvider: React.FunctionComponent = observer(({ children }: ProviderProps) => {
  const { themeStore } = useContext(StoreContext);
  const theme = useMemo(
    () => createMuiTheme({
      palette: {
        type: themeStore.isDark ? 'dark' : 'light',
      },
    }),
    [themeStore.isDark],
  );
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
});

export default ThemeProvider;
